body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #e5e5e5;
}

.react-grid-item.react-grid-placeholder {
  background-color: #313e7e;
}

.ant-table-row {
  background-color: white;
}